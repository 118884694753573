import { template as template_ce8f2c7389074d70a8e71c5831ffbbfe } from "@ember/template-compiler";
const FKText = template_ce8f2c7389074d70a8e71c5831ffbbfe(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
