import { template as template_968038af6f9342f1b10369d447a8d856 } from "@ember/template-compiler";
const FKLabel = template_968038af6f9342f1b10369d447a8d856(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
